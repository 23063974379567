import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { MAYA_TARGETS_TEMPLATE } from 'basics/constants/common.constants';
import ContentCard from 'components/ContentCard';
import QuickForm from 'components/QuickForm';
import { FormShape } from 'components/QuickForm/QuickForm.enums';
import useUploadTargetList from 'components/UploadTargetListForm/UploadTargetListForm.hook';
import { UploadTargetListFormPropsType } from 'components/UploadTargetListForm/UploadTargetListForm.type';
import { ActionButton, ButtonVariant } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import { Icons } from 'yoda-ui/components/Icons/Icon/Icon';
import Loader from 'yoda-ui/components/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const UploadTargetListForm: FC<UploadTargetListFormPropsType> = (
  {
    updateTargetList,
    loading,
    setLoading,
  },
) => {
  const {
    handleFilesExternal,
    uploadLoading,
    uploadTargetListConfig: {
      defaultValue,
      formConfig,
      name,
      validation,
    },
    shouldBeDisabled,
  } = useUploadTargetList(
    updateTargetList,
    setLoading,
  );
  const { t } = useTranslation();

  return (
    <ContentCard sx={ { marginBottom: YodaSpacing.large } }>
      {
        uploadLoading || loading ? (
          <Box
            display='flex'
            justifyContent='center'
            marginTop={ YodaSpacing.xLarge }
          >
            <Loader />
          </Box>) : (
          <>
            {
              !shouldBeDisabled && (
                <>
                  <QuickForm
                    name={ name }
                    form={ { shape: FormShape.formAsset, formConfig: { handleFiles: handleFilesExternal, ...formConfig } } }
                    defaultValue={ defaultValue }
                    validation={ validation }
                  />
                  <ActionButton
                    icon={ Icons.donwload }
                    label={ t('maya_targets_download_example') }
                    href={ MAYA_TARGETS_TEMPLATE }
                    variant={ ButtonVariant.primary }
                  />
                </>
              )
            }
          </>)
      }
    </ContentCard>
  );
};

export default UploadTargetListForm;
