import { useFlags } from 'launchdarkly-react-client-sdk';
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { CepStatus } from 'basics/enums/maya.enums';
import { useCreateVeevaTargetsMutation } from 'basics/graphql/mutations/createVeevaTargets';
import useGetTargetsFacets from 'basics/graphql/queries/getTargetsFacets';
import useGetTargetsFacetsLazy from 'basics/graphql/queries/getTargetsFacetsLazy';
import { targetsFilterToBE } from 'basics/transformers/Target.transformer';
import { CepStateType } from 'basics/types/maya.types';
import { warningToast } from 'basics/utils/toast';
import useDynamicTargetListConfig from 'components/DynamicTargetListForm/DynamicTargetListForm.config';
import { TargetsFacets } from 'generated/maya.types';
import cepState from 'states/cep.states';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { useYodaCreateForm } from 'yoda-ui/yodaForm';

const useDynamicTargetList = (
  updateTargetList: () => Promise<void>,
  loading: boolean,
  setLoading: Dispatch<SetStateAction<boolean>>,

) => {
  const { t } = useTranslation();
  const { getValues, providerFields, useWatchForm } = useYodaCreateForm();
  const { isValid } = useWatchForm();
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const defaultCountries = cepStateValue.cep?.targetFilter?.countries
  || (cepStateValue.cep?.customerInitiative?.country ? [cepStateValue.cep?.customerInitiative?.country] : []);

  const [facets, setFacets] = useState<TargetsFacets>();
  const [country, setCountry] = useState<string | null>(defaultCountries[0] || null);
  const [businessUnit, setBusinessUnit] = useState<string | null>(
    cepStateValue.cep?.targetFilter?.businessUnits ? cepStateValue.cep?.targetFilter?.businessUnits[0] : null,
  );

  const { targetsFacets, loading: targetsFacetsLoading } = useGetTargetsFacets({});
  const { queryGetTargetsFacets, data: filteredTargetsFacets, loading: filteredTargetsFacetsLoading } = useGetTargetsFacetsLazy();
  const flags = useFlags();
  const shouldBeDisabled = isCepApproved
    && (!flags.mayaBdcfcsd653ExtendEditingOfApprovedCep || !flags.mayaBdcfcsd1251EnableEditingOfTargetListOfApprovedCep);

  useEffect(() => {
    if (country && businessUnit) {
      queryGetTargetsFacets({ variables: {
        filters: { countries: [country], businessUnits: [businessUnit] },
      } });
    }
  }, [businessUnit, country, queryGetTargetsFacets]);

  useEffect(() => {
    if (!targetsFacetsLoading) {
      setFacets(targetsFacets as TargetsFacets);
    }
  }, [targetsFacets, targetsFacetsLoading]);

  const {
    dynamicTargetListConfig,
  } = useDynamicTargetListConfig(
    facets as TargetsFacets,
    filteredTargetsFacets as TargetsFacets,
    { ...cepStateValue.cep?.targetFilter, countries: defaultCountries } || {},
    setCountry,
    setBusinessUnit,
  );

  const [createVeevaTargetsMutation] = useCreateVeevaTargetsMutation();

  const handleSubmit = async () => {
    setLoading(true);

    const formattedFilters = targetsFilterToBE(getValues());

    const { error } = await createVeevaTargetsMutation(cepStateValue.cep?._id as string, formattedFilters);

    if (!error) {
      updateTargetList();
    } else {
      warningToast(error);
    }

    setLoading(false);
  };

  const [openScope, setOpenScope] = useState(true);
  const scopeHandleClick = () => {
    setOpenScope(!openScope);
  };

  const [openHcoAttribute, setOpenHcoAttribute] = useState(false);
  const hcoAttributeHandleClick = () => {
    setOpenHcoAttribute(!openHcoAttribute);
  };

  const [openSegment, setOpenSegment] = useState(false);
  const segmentHandleClick = useCallback(() => {
    setOpenSegment(!openSegment);
  }, [openSegment]);

  const [openCustomerAttribute, setOpenCustomerAttribute] = useState(false);
  const customerAttributeHandleClick = useCallback(() => {
    setOpenCustomerAttribute(!openCustomerAttribute);
  }, [openCustomerAttribute]);

  const [openAffinity, setOpenAffinity] = useState(false);
  const affinityHandleClick = useCallback(() => {
    setOpenAffinity(!openAffinity);
  }, [openAffinity]);

  const submitButtonConfig = {
    buttonProps: {
      onClick: handleSubmit,
      buttonType: ButtonType.primary,
      disabled: shouldBeDisabled || loading || !isValid,
    },
    label: t('actions_search_label'),
    loading,
  };

  return {
    affinityHandleClick,
    businessUnit,
    customerAttributeHandleClick,
    dynamicTargetListConfig,
    filteredTargetsFacetsLoading,
    hcoAttributeHandleClick,
    openAffinity,
    openCustomerAttribute,
    openHcoAttribute,
    openScope,
    openSegment,
    providerFields,
    scopeHandleClick,
    segmentHandleClick,
    submitButtonConfig,
    targetsFacetsLoading,
  };
};

export default useDynamicTargetList;
