import { useFlags } from 'launchdarkly-react-client-sdk';
import { useTranslation } from 'react-i18next';
import useTacticsList from './TacticsList.hook';
import { kpiStyle } from 'components/CepForm/Steps/TacticsStep/TacticsList/TacticList.styles';
import GridList from 'components/GridList/GridList';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import Loader from 'yoda-ui/components/Loader';
import { YodaColors } from 'yoda-ui/yodaTheme';

const TacticsList = () => {
  const {
    deleteTacticCallback,
    cancelTacticCallback,
    loading,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    openModal,
    removeTacticLoading,
    cancelTacticLoading,
    rowCountState,
    rowId,
    setOpenModal,
    tacticListColumnConfig,
    tactics,
    isCepApproved,
  } = useTacticsList();
  const { t } = useTranslation();
  const flags = useFlags();

  return (
    <>
      <GridList
        getRowId={ (row) => row._id }
        loading={ loading }
        columnConfig={ tacticListColumnConfig }
        rows={ tactics }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        rowCountState={ rowCountState }
        newElementButton={ newElementButton }
        getRowClassName={ (params) => (params.row.isKeyPerformanceIndicator ? 'kpi-theme' : '') }
        disableColumnMenu={ true }
        sx={ kpiStyle }
      />
      {
        openModal && (
          <ConfirmationDialog
            dialogTitle={
              flags.mayaBdcfcsd653ExtendEditingOfApprovedCep && isCepApproved
                ? t('maya_tactic_modal_cancel')
                : t('maya_tactic_modal_remove')
            }
            onConfirm={
              flags.mayaBdcfcsd653ExtendEditingOfApprovedCep && isCepApproved
                ? () => cancelTacticCallback(rowId)
                : () => deleteTacticCallback(rowId)
            }
            onConfirmLabel={
              removeTacticLoading || cancelTacticLoading
                ? <Loader center size={ 1 } color={ YodaColors.inherit } />
                : t('actions_confirm_label')
            }
            onDismiss={ () => setOpenModal(false) }
            onDismissLabel={ t('actions_cancel_label') }
          >
          </ConfirmationDialog>
        )
      }
    </>
  );
};

export default TacticsList;

