import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { VeevaFatalErrors } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_ALL_VEEVA_FATAL_ERRORS_LAZY = gql`
  query getAllVeevaFatalErrors {
    getAllVeevaFatalErrors {
      id,
      title,
      level,
      lastSeen,
    }
  }
`;

type UseGetAllVeevaFatalErrorsLazy = () => {
  getAllVeevaFatalErrorsLazy: Function;
  loading: boolean;
  error?: ApolloError;
  data: VeevaFatalErrors[];
};

type ResultType = {
  getAllVeevaFatalErrors: VeevaFatalErrors[];
};

const useGetAllVeevaFatalErrorsLazy: UseGetAllVeevaFatalErrorsLazy = () => {
  const [getAllVeevaFatalErrorsLazy, { data, loading, error }] = useLazyQuery<ResultType>(GET_ALL_VEEVA_FATAL_ERRORS_LAZY, {
    fetchPolicy: 'cache-and-network',
    context: { clientName: MAYA_GRAPHQL_ENDPOINT },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getAllVeevaFatalErrorsLazy.ts - useGetAllVeevaFatalErrorsLazy',
    });
  }

  return {
    getAllVeevaFatalErrorsLazy,
    data: data?.getAllVeevaFatalErrors || [],
    loading,
    error,
  };
};

export default useGetAllVeevaFatalErrorsLazy;
