import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useCepList from './CepList.hook';
import { AlertMessageType } from 'basics/enums/maya.enums';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import GridList from 'components/GridList/GridList';
import MayaLayout from 'components/MayaLayout';
import Box from 'yoda-ui/components/Box';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import MultiSelectYoda from 'yoda-ui/components/Form/SelectYoda/MultiSelectYoda';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import Loader from 'yoda-ui/components/Loader';
import { useYodaCreateForm, YodaFormProvider } from 'yoda-ui/yodaForm';
import { YodaColors } from 'yoda-ui/yodaTheme';

const CepList: FC = () => {
  const { providerFields } = useYodaCreateForm();
  const { t } = useTranslation();

  const {
    cepListColumnConfig,
    cepLoading,
    cepTitleFieldConfig,
    customerInitiativeFieldConfig,
    debouncedHandleChangeCepSearch,
    getFilteredRows,
    handleChangeCI,
    handleChangeStatus,
    handleClick,
    menuCustomerEngagementPlanningLabel,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    open,
    removeCepLoading,
    cancelCepLoading,
    restoreCepLoading,
    rowCountState,
    setOpen,
    statusFieldConfig,
    confirmationDialogConfig,
  } = useCepList();

  return (
    <MayaLayout>
      <AlertBanner type={ AlertMessageType.VeevaFatalErrorAlert } />
      <GridList
        loading={ cepLoading }
        getRowId={ (row) => row._id }
        onCellClick={ handleClick }
        title={ menuCustomerEngagementPlanningLabel }
        columnConfig={ cepListColumnConfig }
        rows={ getFilteredRows() }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        rowCountState={ rowCountState }
        newElementButton={ newElementButton }
        customFilters={
          (
            <YodaFormProvider { ...providerFields }>
              <Box>
                <TextYoda
                  onChange={ debouncedHandleChangeCepSearch }
                  { ...cepTitleFieldConfig } />
              </Box>
              <Box>
                <MultiSelectYoda
                  onChange={ handleChangeStatus }
                  { ...statusFieldConfig } />
              </Box>
              <Box>
                <MultiSelectYoda
                  onChange={ handleChangeCI }
                  { ...customerInitiativeFieldConfig } />
              </Box>
            </YodaFormProvider>
          )
        }
        disableColumnMenu={ true }
      />
      {
        open && (
          <ConfirmationDialog
            { ...confirmationDialogConfig }
            onConfirmLabel={
              removeCepLoading || cancelCepLoading || restoreCepLoading
                ? <Loader center size={ 1 } color={ YodaColors.inherit } /> : t('actions_confirm_label')
            }
            onDismiss={ () => setOpen(false) }
            onDismissLabel={ t('actions_cancel_label') }
          >
          </ConfirmationDialog>
        )
      }
    </MayaLayout>
  );
};

export default CepList;
