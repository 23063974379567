import { SelectOption } from 'basics/options/options.types';

const useStrategicImperativeYearOptions = (): SelectOption[] => {
  const currentYear = new Date().getFullYear();
  const years = [currentYear, currentYear + 1, currentYear + 2];

  return years.map((year) => ({ id: year, label: year.toString() }));
};

export default useStrategicImperativeYearOptions;
