import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';
import { getConfigVar } from 'config/config';
import useAuth from 'hooks/useAuth';
import { useQueryParams } from 'hooks/useQueryParams';
import NotAuthenticatedApp from 'routes/NotAuthenticatedApp';

const Login = () => {
  const flags = useFlags();
  const { error } = useQueryParams();
  const { handleLogin } = useAuth();
  const [isUserNotAuthenticated, setUserIsNotAuthenticated] = useState(false);

  useEffect(() => {
    const isAccessDenied = error === 'access_denied';

    if (isAccessDenied) {
      setUserIsNotAuthenticated(true);
    } else {
      handleLogin();
    }

    if (isAccessDenied && !flags.mayaBdcfcsd1196ImplementAlertSystem) {
      window.location.href = getConfigVar('REACT_APP_BACKOFFICE_LOGIN_URI');
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  return <>
    { isUserNotAuthenticated && flags.mayaBdcfcsd1196ImplementAlertSystem && <NotAuthenticatedApp/> }
  </>;
};

export default Login;
