import { FC } from 'react';
import { AlertMessageType } from 'basics/enums/maya.enums';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import Dashboard from 'components/Dashboard/Dashboard';
import MayaLayout from 'components/MayaLayout';

const Maya: FC = () => {
  return (
    <MayaLayout>
      <AlertBanner type={ AlertMessageType.VeevaFatalErrorAlert } />
      <AlertBanner type={ AlertMessageType.MayaAlert } />
      <Dashboard />
    </MayaLayout>
  );
};

export default Maya;
