import { useGetConfig } from 'basics/utils/getConfig.hook';

const useNotAuthenticatedApp = () => {
  const currentModuleConfig = useGetConfig();

  const handleOpenSupportTicketButton = () => {
    window.open(currentModuleConfig.supportAndReport);
  };

  return {
    handleOpenSupportTicketButton,
  };
};

export default useNotAuthenticatedApp;
