import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { VeevaSyncStatusBadgeMap } from 'basics/constants/common.constant';
import { CepStatus, Channel, VeevaSyncStatus } from 'basics/enums/maya.enums';
import { CepStateType } from 'basics/types/maya.types';
import GridListAction from 'components/GridListAction/GridListAction';
import cepState from 'states/cep.states';
import Tag from 'yoda-ui/components/Tags/Tag';
import { YodaFontWeight, YodaSpacing } from 'yoda-ui/yodaTheme';

const useTacticListConfig = (
  editTacticClick: (id: string) => void,
  openModalCallback: (tacticId: string) => void,
) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;
  const tacticGroups = cepStateValue?.tacticGroups;

  const tacticListColumnConfig: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'veevaSyncStatus',
        headerName: t('maya_veeva_sync_status_header_label'),
        flex: 0.15,
        renderCell: (cellValues: GridRenderCellParams) => {
          const badge = VeevaSyncStatusBadgeMap[cellValues.row.veevaSyncStatus || VeevaSyncStatus.PENDING];
          return badge && (
            <Tag
              fitContent
              bgcolor={ badge.bgColor }
              color={ badge.color }
              fontWeight={ YodaFontWeight.medium }
              paddingY={ YodaSpacing.xxxxSmall }
              paddingX={ YodaSpacing.xSmall }
              width={ 'auto' }
              height={ '20px' }
              borderRadius={ '1rem' }
            >
              { badge.label }
            </Tag>
          );
        },
        sortable: false,
      },
      {
        field: 'title',
        headerName: t('maya_tactic_list_title_header_label'),
        flex: 0.4,
        sortable: false,
        cellClassName: 'gridlist-cell-bold',
      },
      {
        field: 'actionOrder',
        headerName: t('maya_tactic_list_actionorder_header_label'),
        flex: 0.1,
        sortable: false,
      },
      {
        field: 'channel',
        headerName: t('maya_tactic_list_channel_header_label'),
        renderCell: (cellValues: GridRenderCellParams) => Channel[cellValues.row.channel as keyof typeof Channel],
        flex: 0.2,
        sortable: false,
        cellClassName: 'list-channel-cell',
      },
      {
        field: 'tacticGroup',
        headerName: t('maya_tactic_list_tactic_group_header_label'),
        flex: 0.15,
        sortable: false,
        hide: !flags.mayaBdcfcsd1585EnableTacticGroup,
        cellClassName: 'list-group-cell',
        valueGetter: (params) => {
          const isGroupTactic = tacticGroups.find((group) => group._id === params.row.previousTactic?._id);
          return isGroupTactic ? `${params.row.previousTactic.title}` : '';
        },
      },
      {
        field: 'action',
        headerName: '',
        disableColumnMenu: true,
        hideSortIcons: true,
        renderCell: (params: GridRenderCellParams) => {
          const actions = [
            {
              label: t('actions_edit_label'),
              callback: () => { editTacticClick(params.row._id); },
            },
          ];

          if (!isCepApproved && flags.mayaBiogenlinc3701EnableTacticDeleting) {
            actions.push({
              label: t('maya_action_delete_tactic_label'),
              callback: () => { openModalCallback(params.row._id); },
            });
          } else if (flags.mayaBdcfcsd653ExtendEditingOfApprovedCep) {
            actions.push({
              label: t('maya_action_cancel_tactic_label'),
              callback: () => { openModalCallback(params.row._id); },
            });
          }

          return (
            <GridListAction
              actions={ actions }
            />
          );
        },
        align: 'right',
        headerAlign: 'right',
        flex: 0.1,
        sortable: false,
      },
    ];
  }, [editTacticClick,
    flags.mayaBiogenlinc3701EnableTacticDeleting,
    openModalCallback,
    t,
    tacticGroups,
    flags.mayaBdcfcsd1585EnableTacticGroup,
    flags.mayaBdcfcsd653ExtendEditingOfApprovedCep,
    isCepApproved,
  ]);

  return { tacticListColumnConfig };
};

export default useTacticListConfig;
