import { useTranslation } from 'react-i18next';
import biogenFunctionOptions from 'basics/options/biogenFunction.options';
import useCepStatusOptions from 'basics/options/cepStatus.options';
import initiativeCountryOptions from 'basics/options/country.options';
import useProductOptions from 'basics/options/product.options';
import useTherapeuticAreaOptions from 'basics/options/therapeuticArea.options';
import { CurrentUser } from 'basics/types/user.types';
import useDashboardFiltersSchema from 'components/DashboardFilters/DashboardFilters.schema';
import { DashboardFiltersConfigType } from 'components/DashboardFilters/DashboardFilters.types';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker';

const useDashboardFiltersConfig = (
  therapeuticArea: string | null,
  currentUserData: CurrentUser | null | undefined,
) => {
  const { t } = useTranslation();
  const currentTA = therapeuticArea ?? '';
  const dashboardFiltersSchema = useDashboardFiltersSchema();

  let dateStart = currentUserData?.dashboardFilters?.dateStart;
  if (typeof dateStart === 'string') {
    dateStart = new Date(dateStart);
    dateStart = new Date(
      dateStart.getUTCFullYear(),
      dateStart.getUTCMonth(),
      dateStart.getUTCDate(),
      dateStart.getUTCHours(),
      dateStart.getUTCMinutes(),
      dateStart.getUTCSeconds(),
    );
  }

  let dateEnd = currentUserData?.dashboardFilters?.dateEnd;
  if (typeof dateEnd === 'string') {
    dateEnd = new Date(dateEnd);
    dateEnd = new Date(
      dateEnd.getUTCFullYear(),
      dateEnd.getUTCMonth(),
      dateEnd.getUTCDate(),
      dateEnd.getUTCHours(),
      dateEnd.getUTCMinutes(),
      dateEnd.getUTCSeconds(),
    );
  }

  const dateRange: DateRange = [
    dateStart || null,
    dateEnd || null,
  ];

  const dashboardFiltersConfig: DashboardFiltersConfigType = {
    countries: {
      label: t('maya_dashboard_filters_countries_label'),
      name: 'countries',
      placeholder: t('maya_dashboard_filters_countries_placeholder'),
      required: true,
      defaultValue: currentUserData?.dashboardFilters?.countries || [],
      options: initiativeCountryOptions,
      validation: dashboardFiltersSchema.countries,
    },
    therapeuticArea: {
      label: t('maya_dashboard_filters_ta_label'),
      name: 'therapeuticArea',
      placeholder: t('maya_dashboard_filters_ta_placeholder'),
      options: useTherapeuticAreaOptions(),
      defaultValue: currentUserData?.dashboardFilters?.therapeuticArea,
      required: true,
      validation: dashboardFiltersSchema.therapeuticArea,
    },
    product: {
      label: t('maya_dashboard_filters_product_label'),
      name: 'product',
      placeholder: t('maya_dashboard_filters_product_placeholder'),
      options: useProductOptions(currentTA),
      defaultValue: currentUserData?.dashboardFilters?.product || [],
      required: false,
      validation: dashboardFiltersSchema.product,
    },
    cepFunction: {
      label: t('maya_dashboard_filters_cep_function_label'),
      name: 'cepFunction',
      placeholder: t('maya_dashboard_filters_cep_function_placeholder'),
      options: biogenFunctionOptions,
      defaultValue: currentUserData?.dashboardFilters?.cepFunction || [],
      required: false,
      validation: dashboardFiltersSchema.cepFunction,
    },
    cepStatus: {
      label: t('maya_dashboard_filters_cep_status_label'),
      name: 'cepStatus',
      placeholder: t('maya_dashboard_filters_cep_status_placeholder'),
      options: useCepStatusOptions(),
      defaultValue: currentUserData?.dashboardFilters?.cepStatus || [],
      required: false,
      validation: dashboardFiltersSchema.cepStatus,
    },
    date: {
      startText: t('maya_dashboard_filters_cep_start_date_label'),
      endText: t('maya_dashboard_filters_cep_end_date_label'),
      name: 'date',
      defaultValue: dateRange,
      disablePast: false,
      startRequired: false,
      endRequired: false,
      validation: dashboardFiltersSchema.date,
    },
  };

  return { dashboardFiltersConfig };
};

export default useDashboardFiltersConfig;
