import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import useTacticKanban from 'components/CepForm/Steps/TacticsStep/TacticKanban/TacticKanban.hook';
import {
  ButtonHeadStyle,
  TacticCardListStyle,
  TacticCardsLoaderStyle,
  TacticCardWrapperStyle,
  TacticKanbanStyle,
} from 'components/CepForm/Steps/TacticsStep/TacticKanban/TacticKanban.styles';
import TacticCard from 'components/TacticCard/TacticCard';
import { Tactic } from 'generated/maya.types';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import Card from 'yoda-ui/components/Card/Card';
import Loader from 'yoda-ui/components/Loader';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const TacticKanban = () => {
  const {
    groupedTactics,
    newElementButton,
    printViewButton,
    updateTacticActionOrder,
    loading,
  } = useTacticKanban();
  const { t } = useTranslation();

  return (
    <Box>
      <Box sx={ ButtonHeadStyle }>
        <Button
          buttonType={ ButtonType.neutral }
          onClick={ printViewButton.printViewClick }
        >
          { printViewButton.printViewLabel }
        </Button>
        {
          newElementButton?.newElementLabel ? (<Button
            buttonType={ ButtonType.primary }
            onClick={ newElementButton.newElementClick }
            disabled={ newElementButton.newElementDisabled }
          >
            { newElementButton.newElementLabel }
          </Button>) : null
        }
      </Box>
      {
        loading && (
          <Box sx={ TacticCardsLoaderStyle }>
            <Loader center/>
          </Box>
        )
      }
      <Box sx={ TacticKanbanStyle }>
        <DragDropContext
          onDragEnd={ (result) => updateTacticActionOrder(result) }
        >
          {
            Object.keys(groupedTactics).map((actionOrder: string) => {
              const tactics = groupedTactics[actionOrder];
              return (
                tactics.length > 0 && (
                  <Droppable droppableId={ actionOrder } key={ actionOrder }>
                    {
                      (provided) => (
                        <div
                          ref={ provided.innerRef }
                        >
                          <Card key={ actionOrder } variant="outlined" sx={ TacticCardWrapperStyle }>
                            <Box marginBottom={ YodaSpacing.small } >
                              { `${t('maya_tactic_action_order')} ${actionOrder}` }
                            </Box>
                            <Box sx={ TacticCardListStyle }>
                              {
                                tactics.map(
                                  (tactic: Tactic, index: number) => (
                                    <Draggable draggableId={ tactic._id } index={ index } key={ tactic._id }>
                                      {
                                        (dragProvided) => (
                                          <div
                                            ref={ dragProvided.innerRef }
                                            { ...dragProvided.draggableProps }
                                            { ...dragProvided.dragHandleProps }
                                          >
                                            <TacticCard key={ tactic._id } tactic={ tactic } readOnly={ false } />
                                          </div>
                                        )
                                      }
                                    </Draggable>
                                  ),
                                )
                              }
                            </Box>
                          </Card>
                          { provided.placeholder }
                        </div>
                      )
                    }
                  </Droppable>
                )
              );
            })
          }
        </DragDropContext>
      </Box>
    </Box>
  );
};

export default TacticKanban;
