import { cloneDeep } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSnapshot } from 'valtio';
import { PAGINATION_PAGE_SIZE } from 'basics/constants/common.constant';
import { CepStatus, TacticMode, TacticStatus, Channel } from 'basics/enums/maya.enums';
import { useSetTacticStatusToCancelledMutation } from 'basics/graphql/mutations/setTacticStatusToCancelled';
import { useSetTacticStatusToDeletedMutation } from 'basics/graphql/mutations/setTacticStatusToDeleted';
import useGetAllTacticsQuery from 'basics/graphql/queries/getAllTactics';
import { CepStateType } from 'basics/types/maya.types';
import { succesToast, warningToast } from 'basics/utils/toast';
import useTacticListConfig from 'components/CepForm/Steps/TacticsStep/TacticsList/TacticsList.config';
import { Tactic } from 'generated/maya.types';
import cepState from 'states/cep.states';
import tacticState from 'states/tactic.states';

const useTacticsList = () => {
  const { t } = useTranslation();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const cepId = cepStateValue.cep?._id;
  const isCepApproved = cepStateValue.cep?.status === CepStatus.APPROVED;

  const [setTacticStatusToDeleted, { loading: removeTacticLoading }] = useSetTacticStatusToDeletedMutation();
  const [setTacticStatusToCancelled, { loading: cancelTacticLoading }] = useSetTacticStatusToCancelledMutation();
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGINATION_PAGE_SIZE);
  const [rowCountState, setRowCountState] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const [rowId, setRowId] = useState('');
  const [tactics, setTactics] = useState<Tactic[]>([]);

  const queryOptions = useMemo(
    () => ({
      limit: pageSize,
      offset: page * pageSize,
    }),
    [page, pageSize],
  );

  const queryFilters = useMemo(
    () => ({
      cep: cepId,
      status: [TacticStatus.DRAFT, TacticStatus.APPROVED],
      channel: Object.values(Channel).filter((channel) => channel !== Channel.group),
    }),
    [cepId],
  );

  const { data, loading, count } = useGetAllTacticsQuery(queryOptions, queryFilters);

  useEffect(() => {
    setRowCountState((prevRowCountState) => (count !== undefined ? count : prevRowCountState));
  }, [count, setRowCountState]);

  useEffect(() => {
    if (!loading) {
      setTactics(data);
    }
  }, [data, loading]);

  const newTacticClick = () => {
    tacticState.mode = TacticMode.form;
  };

  const editTacticClick = (id: string) => {
    const currentTactic = data?.find((tactic) => tactic._id === id);
    if (currentTactic) {
      tacticState.tactic = currentTactic;
      tacticState.mode = TacticMode.form;
    }
  };

  const onPageChange = (newPage: number) => {
    setPage(newPage);
  };

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const newElementButton = {
    newElementLabel: t('maya_list_new_element_label', { elementTitle: 'Tactic' }),
    newElementClick: newTacticClick,
    newElementDisabled: isCepApproved,
  };

  const deleteTacticCallback = async (tacticId: string) => {
    const { data: tacticDeleted, error: tacticdDeletedError } = await setTacticStatusToDeleted(tacticId);
    if (tacticDeleted && !tacticdDeletedError) {
      succesToast(t('maya_action_deleting_tactic_success'));
      const clonedTactics = cloneDeep(tactics);
      const targetTacticsIndex = clonedTactics.findIndex((tactic) => { return tactic._id === tacticId; });
      if (targetTacticsIndex !== -1) {
        clonedTactics.splice(targetTacticsIndex, 1);
        setTactics(clonedTactics);
      }
    } else {
      warningToast(tacticdDeletedError || t('errors_standard'));
    }

    setOpenModal(false);
  };

  const cancelTacticCallback = async (tacticId: string) => {
    const { data: tacticCancelled, error: tacticCancelledError } = await setTacticStatusToCancelled(tacticId);
    if (tacticCancelled && !tacticCancelledError) {
      succesToast(t('maya_action_canceling_tactic_success'));
      const clonedTactics = cloneDeep(tactics);
      const targetTacticsIndex = clonedTactics.findIndex((tactic) => { return tactic._id === tacticId; });
      if (targetTacticsIndex !== -1) {
        clonedTactics.splice(targetTacticsIndex, 1);
        setTactics(clonedTactics);
      }
    } else {
      warningToast(tacticCancelledError || t('errors_standard'));
    }

    setOpenModal(false);
  };

  const openModalCallback = (tacticId: string) => {
    setOpenModal(true);
    setRowId(tacticId);
  };

  const { tacticListColumnConfig } = useTacticListConfig(editTacticClick, openModalCallback);

  return {
    deleteTacticCallback,
    cancelTacticCallback,
    editTacticClick,
    loading,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    openModal,
    removeTacticLoading,
    cancelTacticLoading,
    rowCountState,
    rowId,
    setOpenModal,
    tacticListColumnConfig,
    tactics,
    isCepApproved,
  };
};

export default useTacticsList;
