import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import LanOutlinedIcon from '@mui/icons-material/LanOutlined';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Channel, VeevaSyncStatus } from 'basics/enums/maya.enums';
import useTacticCard from 'components/TacticCard/TacticCard.hook';

import {
  CustomContentCardStyle,
  TacticCardFontStyle,
  TacticCardTitleStyle,
  clickableIdStyle,
  iconsStyle,
  tacticGroupIconStyle,
} from 'components/TacticCard/TacticCard.styles';
import { TacticCardType } from 'components/TacticCard/TacticCard.types';
import { ButtonBackgroundType, ButtonVariant, IconButton } from 'yoda-ui/components/BiogenLincDS/Buttons';
import Box from 'yoda-ui/components/Box';
import Card from 'yoda-ui/components/Card/Card';
import ConfirmationDialog from 'yoda-ui/components/Dialogs/ConfirmationDialog';
import { Icons } from 'yoda-ui/components/Icons/Icon';
import Loader from 'yoda-ui/components/Loader';
import { YodaColors, YodaSpacing } from 'yoda-ui/yodaTheme';

const TacticCard: FC<TacticCardType> = ({ tactic, readOnly }) => {
  const { t } = useTranslation();
  const flags = useFlags();
  const {
    deleteTacticCallback,
    cancelTacticCallback,
    editTacticCallback,
    getCategoryForChannel,
    getStyleFromCategory,
    getStyleFromVeevaSyncStatus,
    handleCopy,
    openModal,
    openModalCallback,
    removeTacticLoading,
    cancelTacticLoading,
    setOpenModal,
    extractNumberFromText,
    tacticGroup,
    getStylesFromNumberGroup,
    isCepApproved,
  } = useTacticCard(tactic);

  return (
    <>
      <Card
        key={ tactic._id }
        variant="outlined"
        sx={
          tactic.isKeyPerformanceIndicator
            ? { ...CustomContentCardStyle, border: `solid 2px ${YodaColors.primaryBlue}` }
            : CustomContentCardStyle
        }
      >
        <Box display={ 'flex' }>
          <Box sx={ getStyleFromCategory(getCategoryForChannel(tactic.channel), tactic.channel) }>
            { getCategoryForChannel(tactic.channel) }
          </Box>
          {
            flags.mayaBdcfcsd1585EnableTacticGroup && tacticGroup
              ? <Box sx={ getStylesFromNumberGroup(extractNumberFromText(tacticGroup?.title)) }>
                <LanOutlinedIcon sx={ tacticGroupIconStyle }></LanOutlinedIcon>
                { extractNumberFromText(tacticGroup?.title) }
              </Box>
              : null
          }

          {
            readOnly ? null
              : <Box sx={ getStyleFromVeevaSyncStatus(tactic.veevaSyncStatus as VeevaSyncStatus) }>
                { tactic.veevaSyncStatus }
              </Box>
          }
        </Box>
        <Box marginTop={ YodaSpacing.xxxSmall } paddingLeft={ YodaSpacing.xSmall }>
          <Box sx={ TacticCardTitleStyle } >
            { tactic.title }
          </Box>
          <Box sx={ clickableIdStyle }>
            { `ID: ${tactic.formattedTacticId}` }
            {
              readOnly ? null
                : <IconButton
                  onClick={ () => handleCopy(tactic.formattedTacticId) }
                  icon={ Icons.contentCopy }
                  variant={ ButtonVariant.primary }
                  backgroundType={ ButtonBackgroundType.noBackground }
                />
            }
          </Box>
          <Box sx={ TacticCardFontStyle }>
            { `Channel: ${Channel[tactic.channel as unknown as keyof typeof Channel]}` }
          </Box>
          <Box sx={ TacticCardFontStyle } >
            {
              `Date: ${tactic.dateStart ? new Date(tactic.dateStart).toLocaleDateString() : ''} 
                - ${tactic.dateEnd ? new Date(tactic.dateEnd).toLocaleDateString() : ''}`
            }
          </Box>
        </Box>
        {
          readOnly ? null
            : <Box sx={ iconsStyle } >
              <EditOutlinedIcon color='inherit' onClick={ () => editTacticCallback() } />
              {
                flags.mayaBiogenlinc3701EnableTacticDeleting
                  ? <DeleteOutlineOutlinedIcon onClick={ () => openModalCallback(tactic._id) }/>
                  : null
              }
            </Box>
        }
      </Card>
      {
        openModal && (
          <ConfirmationDialog
            dialogTitle={
              flags.mayaBdcfcsd653ExtendEditingOfApprovedCep && isCepApproved
                ? t('maya_tactic_modal_cancel')
                : t('maya_tactic_modal_remove')
            }
            onConfirm={
              flags.mayaBdcfcsd653ExtendEditingOfApprovedCep && isCepApproved
                ? () => cancelTacticCallback(tactic._id)
                : () => deleteTacticCallback(tactic._id)
            }
            onConfirmLabel={
              removeTacticLoading || cancelTacticLoading
                ? <Loader center size={ 1 } color={ YodaColors.inherit } />
                : t('actions_confirm_label')
            }
            onDismiss={ () => setOpenModal(false) }
            onDismissLabel={ t('actions_cancel_label') }
          >
          </ConfirmationDialog>
        )
      }
    </>
  );
};

export default TacticCard;

