import {
  AltRoute,
  Person,
  Phone,
  AllInbox,
  Email,
  Event,
  Http,
  QuestionMark,
} from '@mui/icons-material';
import { format } from 'date-fns';
import { Tactic, Channel } from 'generated/maya.types';

const useMiViewTacticListRow = (tactic: Tactic, childRecords: Tactic[] | null) => {
  const { dateStart, dateEnd } = tactic;

  const dateContent = (dateStart && dateEnd)
    ? `${format(new Date(dateStart), 'MM/dd')} - ${format(new Date(dateEnd), 'MM/dd')}`
    : 'null';

  const pickChannelIcon = () => {
    if (childRecords && childRecords.length > 0) {
      return AltRoute;
    }
    switch (tactic.channel) {
      case Channel.oneToOne:
        return Person;
      case Channel.f2fMeeting:
        return Person;
      case Channel.f2fEvent:
        return Person;
      case Channel.telephone:
        return Phone;
      case Channel.broadcastEmail:
        return AllInbox;
      case Channel.virtualEvent:
        return Event;
      case Channel.approvedEmail:
        return Email;
      case Channel.virtualMeeting:
        return Email;
      case Channel.hybridEvent:
        return Event;
      case Channel.webLogIn:
        return Http;
      case Channel.webNoLogIn:
        return Http;
      default:
        return QuestionMark;
    }
  };

  const getTacticContent = (tacticItem: Tactic) => {
    if (!tacticItem) {
      return null;
    }

    let tacticContent: string | null | undefined = '';

    if (tacticItem?.keyMessage?.name) {
      tacticContent = tacticItem.keyMessage.name;
    } else if (tacticItem.approvedDocumentId) {
      tacticContent = tacticItem.approvedDocumentId;
    } else if (tacticItem.destinationUrl) {
      tacticContent = tacticItem.destinationUrl;
    } else if (tacticItem.dropMaterial && tacticItem.dropMaterial?.name) {
      tacticContent = tacticItem.dropMaterial.name;
    } else {
      tacticContent = tacticItem.assetName;
    }

    return tacticContent || null;
  };

  return {
    dateContent,
    pickChannelIcon,
    getTacticContent,
  };
};

export default useMiViewTacticListRow;
