import { useFlags } from 'launchdarkly-react-client-sdk';
import { AlertMessageType } from 'basics/enums/maya.enums';
import veevaFatalErrorsState from 'states/veevaFatalErrors.states';

const useAlertBanner = (type: AlertMessageType) => {
  const flags = useFlags();
  const veevaFatalErrorAlert = flags.mayaBdcfcsd1196VeevaSyncIssuesAlertMessage;
  const mayaAlert = flags.mayaBiogenlinc4416AlertMessage;
  const { hasErrors } = veevaFatalErrorsState;

  switch (type) {
    case AlertMessageType.VeevaFatalErrorAlert:
      if (veevaFatalErrorAlert.enabled && hasErrors) {
        return veevaFatalErrorAlert;
      }
      break;

    case AlertMessageType.MayaAlert:
      if (mayaAlert.enabled) {
        return mayaAlert;
      }
      break;

    default:
      return mayaAlert;
  }

  return null;
};

export default useAlertBanner;
