import { Box } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { FC } from 'react';
import useStrategicImperativeList from './StrategicImperativeList.hook';
import { AlertMessageType } from 'basics/enums/maya.enums';
import AlertBanner from 'components/AlertBanner/AlertBanner';
import GridList from 'components/GridList/GridList';
import MayaLayout from 'components/MayaLayout';
import TextYoda from 'yoda-ui/components/Form/TextYoda';
import { useYodaCreateForm, YodaFormProvider } from 'yoda-ui/yodaForm';

const StrategicImperativeList: FC = () => {
  const { providerFields } = useYodaCreateForm();
  const flags = useFlags();
  const {
    debouncedHandleChangeSiTitle,
    handleClick,
    loading,
    menuStrategicImperativeLabel,
    newElementButton,
    onPageChange,
    onPageSizeChange,
    rowCountState,
    siTitleFieldConfig,
    strategicImperativeListColumnConfig,
    strategicImperatives,
  } = useStrategicImperativeList();

  return (
    <MayaLayout>
      <AlertBanner type={ AlertMessageType.VeevaFatalErrorAlert } />
      <GridList
        getRowId={ (row) => row._id }
        loading={ loading }
        onCellClick={ handleClick }
        title={ menuStrategicImperativeLabel }
        columnConfig={ strategicImperativeListColumnConfig }
        rows={ strategicImperatives }
        onPageChange={ onPageChange }
        onPageSizeChange={ onPageSizeChange }
        newElementButton={
          flags.mayaBiogenlinc4493EnableStrategicImperativeManagement
            ? newElementButton
            : undefined
        }
        rowCountState={ rowCountState }
        disableColumnMenu={ true }
        customFilters={
          (
            <YodaFormProvider { ...providerFields }>
              <Box>
                <TextYoda
                  onChange={ debouncedHandleChangeSiTitle }
                  { ...siTitleFieldConfig } />
              </Box>
            </YodaFormProvider>
          )
        }
      />
    </MayaLayout>
  );
};

export default StrategicImperativeList;
