import { ApolloError, gql, useLazyQuery } from '@apollo/client';
import { MAYA_GRAPHQL_ENDPOINT } from 'basics/constants/common.constants';
import { KeyMessage } from 'generated/maya.types';
import { extractApolloErrorDetails } from 'services/graphql/extractApolloErrorDetails';
import Logger from 'services/logger';

const GET_KEY_MESSAGES_LAZY = gql`
query GetKeyMessages($filters: KeyMessagesFilter) {
  getKeyMessages(filters: $filters) {
    ID
    BusinessUnit,
    Category,
    Country,
    Description,
    DetailGroupName,
    EndDate,
    Function,
    KeyName,
    MarketAccess,
    Medical,
    Commercial,
    ProductName,
    Sales,
    StartDate,
    Status,
    Type,
    VeevaExternalID,
  }
}
`;

type ResultType = {
  getKeyMessages: KeyMessage[];
};

type UseGetKeyMessagesLazy = () => {
  getKeyMessagesLazy: Function;
  loading: boolean;
  error?: ApolloError;
  data: KeyMessage[];
};

const useGetKeyMessagesLazyQuery: UseGetKeyMessagesLazy = () => {
  const [getKeyMessagesLazy, { data, loading, error }] = useLazyQuery<ResultType>(GET_KEY_MESSAGES_LAZY, {
    fetchPolicy: 'cache-and-network',
    context: {
      clientName: MAYA_GRAPHQL_ENDPOINT,
      queue: true,
    },
  });

  if (error) {
    const customContext = extractApolloErrorDetails(error);
    Logger.logError({
      error,
      customContext,
      transactionName: 'getKeyMessagesLazy.ts - useGetKeyMessagesLazyQuery',
    });
  }

  return {
    getKeyMessagesLazy,
    data: data?.getKeyMessages || [],
    loading,
    error,
  };
};

export default useGetKeyMessagesLazyQuery;
