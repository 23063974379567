import { YodaFieldValue } from 'yoda-ui/yodaForm/yodaForm.types';

export const strategicImperativeToBE = (data: YodaFieldValue) => {
  return {
    title: data.title,
    description: data.description,
    therapeuticArea: data.therapeuticArea,
    year: data.year,
  };
};

