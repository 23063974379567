import { useTranslation } from 'react-i18next';
import { array, mixed } from 'yup';
import { BiogenProduct, BiogenTherapeuticArea } from 'basics/enums/biogen.enums';
import { BiogenFunction, CepStatus } from 'basics/enums/maya.enums';
import { testEndDayAfterStartDay } from 'basics/utils/dates/dates.utils';
import { InitiativeCountry } from 'generated/maya.types';

const useDashboardFiltersSchema = () => {
  const { t } = useTranslation();

  return {
    countries: array().of(mixed<InitiativeCountry>().oneOf(Object.values(InitiativeCountry)))
      .test({
        name: 'countries_not_empty',
        test: (countries, context) => {
          if (countries?.length === 0) {
            return context.createError(
              {
                message: t(
                  'maya_dashboard_countries_error',
                ),
              },
            );
          }

          return true;
        },
      }),
    therapeuticArea: mixed<BiogenTherapeuticArea | ''>().oneOf([...Object.values(BiogenTherapeuticArea), ''])
      .test({
        name: 'TA_not_empty',
        test: (TA, context) => {
          if (!TA) {
            return context.createError(
              {
                message: t(
                  'maya_dashboard_ta_error',
                ),
              },
            );
          }

          return true;
        },
      }),
    product: array().of(mixed<BiogenProduct>().oneOf(Object.values(BiogenProduct))).nullable(),
    cepFunction: array().of(mixed<BiogenFunction>().oneOf(Object.values(BiogenFunction))).nullable(),
    cepStatus: array().of(mixed<CepStatus>().oneOf(Object.values(CepStatus))).nullable(),
    date: mixed()
      .test({
        name: 'endDate must be after startDate',
        message: t('timeSlotBooking_endDate_errorMessage_earlierThanStart'),
        test: testEndDayAfterStartDay,
      }),
  };
};

export default useDashboardFiltersSchema;
