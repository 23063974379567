import { startOfDay } from 'date-fns';
import { DateRange } from 'yoda-ui/components/Form/DateRangePicker/DateRangePicker.types';

export const formatUTCDate = (startDate?: Date, endDate?: Date) => {
  let dateStartUTC = null;
  let dateEndUTC = null;

  if (startDate) {
    const dateStart = new Date(startDate);
    dateStartUTC = new Date(
      dateStart.getUTCFullYear(),
      dateStart.getUTCMonth(),
      dateStart.getUTCDate(),
      dateStart.getUTCHours(),
      dateStart.getUTCMinutes(),
      dateStart.getUTCSeconds(),
    );
  }

  if (endDate) {
    const dateEnd = new Date(endDate);
    dateEndUTC = new Date(
      dateEnd.getUTCFullYear(),
      dateEnd.getUTCMonth(),
      dateEnd.getUTCDate(),
      dateEnd.getUTCHours(),
      dateEnd.getUTCMinutes(),
      dateEnd.getUTCSeconds(),
    );
  }

  return [dateStartUTC ? startOfDay(dateStartUTC) : dateStartUTC, dateEndUTC ? startOfDay(dateEndUTC) : dateEndUTC] as DateRange;
};
