import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import useNotAuthenticatedApp from './NotAuthenticatedApp.hook';
import EmptySVG from 'basics/images/Empty-State.svg';
import LargeContainer from 'components/Layout/LargeContainer';
import Box from 'yoda-ui/components/Box';
import Button from 'yoda-ui/components/Button';
import { ButtonType } from 'yoda-ui/components/Button/Button.types';
import { YodaColors, YodaFontSize, YodaSpacing } from 'yoda-ui/yodaTheme';

const NotAuthenticatedApp: FC = () => {
  const { t } = useTranslation();
  const { handleOpenSupportTicketButton } = useNotAuthenticatedApp();

  return (
    <LargeContainer
      display="column"
      paddingRight={ YodaSpacing.xLarge }
      paddingLeft={ YodaSpacing.xLarge }
    >
      <Box fontSize={ YodaFontSize.xxxxLarge } color={ YodaColors.gray5 }>
        { t('maya_access_denied_code') }
      </Box>
      <Box marginBottom={ YodaSpacing.small }>
        { t('maya_access_denied_text') }
      </Box>
      <Button
        buttonType={ ButtonType.primary }
        onClick={ handleOpenSupportTicketButton }
      >
        { t('footer_support') }
      </Button>
      <Box width="700px" marginX="auto" marginTop={ YodaSpacing.small }>
        <img alt="" src={ EmptySVG } width="100%"/>
      </Box>
    </LargeContainer>
  );
};
export default NotAuthenticatedApp;
