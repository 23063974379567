import useFlags from 'launchdarkly-react-client-sdk/lib/useFlags';
import { cloneDeep } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useSnapshot } from 'valtio';
import { mayaCepListRoute, mayaCustomerInitiativeCreateRoute } from 'basics/constants/routes.constants';
import { CepStatus, VeevaSyncStatus } from 'basics/enums/maya.enums';
import { useBulkCreateTargetListMutation } from 'basics/graphql/mutations/bulkCreateTargetList';
import { useBulkUpdateTacticMutation } from 'basics/graphql/mutations/bulkUpdateTactic';
import { useDuplicateCepMutation } from 'basics/graphql/mutations/duplicateCep';
import { useUpdateCepMutation } from 'basics/graphql/mutations/updateCep';
import useGetCepByIdQuery from 'basics/graphql/queries/getCepById';
import useGetEventByIdQuery from 'basics/graphql/queries/getEventById';
import { eventToEventState } from 'basics/transformers/Event.transformer';
import { eventToTactic } from 'basics/transformers/Tactic.transformer';
import { CepStateType, EventState, EventStateType } from 'basics/types/maya.types';
import { warningToast } from 'basics/utils/toast';
import { validateMdmId } from 'basics/utils/validateMdmId';
import { CepFormSubmitCallbackType, DuplicateTacticAndTargetsFromEvent } from 'components/CepForm/CepForm.types';
import { CustomerEngagementPlanning } from 'generated/maya.types';
import { useQueryParams } from 'hooks/useQueryParams';
import cepState from 'states/cep.states';
import eventState from 'states/event.states';

const useCepDuplicate = () => {
  const flags = useFlags();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [isOpenCIDialog, setIsOpenCIDialog] = useState(false);
  const isRedirect = useRef(false);
  const [prefix, setPrefix] = useState('');

  const { cepSourceId, eventId } = useQueryParams();
  const [duplicateCustomerEngagementPlanning] = useDuplicateCepMutation();
  const [updateCustomerEngagementPlanning] = useUpdateCepMutation();
  const [bulkCreateTargetList] = useBulkCreateTargetListMutation();
  const [bulkUpdateTactic] = useBulkUpdateTacticMutation();

  const cepStateValue = useSnapshot(cepState) as CepStateType;
  const eventStateValue = useSnapshot(eventState) as EventStateType;

  const {
    data: event,
    loading: loadingEvent,
    error: errorEvent,
  } = useGetEventByIdQuery(eventId || null, !eventId || !!eventStateValue.eventId);
  const { data: customerEngagementPlanning, error: errorCep } = useGetCepByIdQuery(cepSourceId || null);

  const isApprovedCEP = customerEngagementPlanning?.status === CepStatus.APPROVED;
  const isDeepDuplication = isApprovedCEP && flags.mayaBdcfcsd1227EnableCepDeepDuplication;

  const onClickNextCustomerInitiative = useCallback((id: string) => {
    eventState.ciId = id;
  }, []);

  const onClickNewCustomerInitiative = useCallback(() => {
    navigate(`${mayaCustomerInitiativeCreateRoute}?eventId=${eventId}&cepSourceId=${cepSourceId}`);
    isRedirect.current = true;
  }, [navigate, eventId, cepSourceId]);

  const cloneCep = useCallback((cepData: CustomerEngagementPlanning, eventData?: EventState) => {
    const parentCi = cepData.customerInitiative.isTemplate ? undefined : cepData.customerInitiative;
    const clonedCep: Partial<CustomerEngagementPlanning> = cloneDeep(cepData);

    clonedCep._id = '';
    clonedCep.customerInitiative = parentCi;
    clonedCep.dateStart = isDeepDuplication ? customerEngagementPlanning.dateStart : null;
    clonedCep.dateEnd = isDeepDuplication ? customerEngagementPlanning.dateEnd : null;
    clonedCep.lastVeevaSync = null;
    clonedCep.lastVeevaSyncError = null;
    clonedCep.status = CepStatus.DRAFT;
    clonedCep.title = `${prefix} - ${eventData ? eventData.title : clonedCep.title}`;
    clonedCep.updated = undefined;
    clonedCep.veevaSyncStatus = VeevaSyncStatus.PENDING;

    clonedCep.npsGoal = isDeepDuplication ? Number(customerEngagementPlanning.npsGoal) : null;
    clonedCep.reachGoal = isDeepDuplication ? Number(customerEngagementPlanning.reachGoal) : null;
    clonedCep.targetEngagementGoal = isDeepDuplication ? Number(customerEngagementPlanning.targetEngagementGoal) : null;

    return clonedCep;
  }, [customerEngagementPlanning, isDeepDuplication, prefix]);

  useEffect(() => {
    if (customerEngagementPlanning && eventStateValue.event.title) {
      cepState.cep = cloneCep(customerEngagementPlanning, eventStateValue.event);
    } else if (customerEngagementPlanning) {
      cepState.cep = cloneCep(customerEngagementPlanning);
    }
  }, [eventStateValue.event, customerEngagementPlanning, cloneCep]);

  useEffect(() => {
    if (errorCep) {
      warningToast(t('common.wording.error404'));
      navigate(mayaCepListRoute);
    }
  }, [t, navigate, errorCep]);

  useEffect(() => {
    if (event && cepState.cep) {
      eventState.event = eventToEventState(event);
      eventState.eventId = eventId;
    }

    if (errorEvent) {
      warningToast(t('common.wording.error404'));
      navigate(mayaCepListRoute);
    }
  }, [event, eventId, prefix, errorEvent, t, navigate]);

  useEffect(() => {
    if (customerEngagementPlanning) {
      const { isTemplate } = customerEngagementPlanning.customerInitiative;

      if (!eventId && isTemplate) {
        setPrefix('From');
      } else if (eventId && isTemplate) {
        setPrefix('Name of the events');
      } else {
        setPrefix('Copy');
      }
    }
  }, [customerEngagementPlanning, eventId]);

  useEffect(() => {
    setIsOpenCIDialog(Boolean(!eventStateValue.ciId && eventId));
  }, [eventStateValue.ciId, eventId]);

  useEffect(() => {
    return function cleanup() {
      cepState.cep = {};
      cepState.tactics = [];
      cepState.targetList = [];
    };
  }, []);

  useEffect(() => {
    return function cleanup() {
      if (!isRedirect.current) {
        eventState.eventId = '';
        eventState.ciId = '';
      }
    };
  }, []);

  const duplicateTacticAndTargetsFromEvent: DuplicateTacticAndTargetsFromEvent = async (customerEngagementPlanningData) => {
    const { cep, tactics } = customerEngagementPlanningData;
    const bulkInputTactics = tactics.map((tactic) => eventToTactic(cep, tactic, eventStateValue.event));
    const validTargets = eventStateValue.event.targets?.filter((target) => validateMdmId(target.mdmId));
    const isHaveInvalidMdmId = eventStateValue.event.targets?.length !== validTargets?.length;

    if (isHaveInvalidMdmId) {
      warningToast(t('maya_cep_warning_about_invalid_mdmid'));
    }

    const { data: updateTactics } = await bulkUpdateTactic(cep._id, bulkInputTactics);
    const { data: targets } = await bulkCreateTargetList(cep._id, validTargets || []);

    if (updateTactics) {
      cepState.tactics = updateTactics;
    }

    if (targets) {
      cepState.targetList = targets;
    }
  };

  const submitCallback: CepFormSubmitCallbackType = async (cep) => {
    if (cepStateValue.cep?._id) {
      const customerEngagementPlanningData = await updateCustomerEngagementPlanning(
        cepStateValue.cep._id,
        cep,
        true,
      );

      return customerEngagementPlanningData;
    }

    const newCEP = { ...cep,
      npsGoal: isDeepDuplication ? cepState?.cep?.npsGoal : null,
      reachGoal: isDeepDuplication ? cepState?.cep?.reachGoal : null,
      targetEngagementGoal: isDeepDuplication ? cepState?.cep?.targetEngagementGoal : null };

    const sendToVeeva = true;
    const deepDuplication = customerEngagementPlanning?.status === CepStatus.APPROVED;

    const { data: customerEngagementPlanningData, error } = await duplicateCustomerEngagementPlanning(
      cepSourceId,
      { ...newCEP, sourceId: cepSourceId },
      sendToVeeva,
      deepDuplication,
    );

    if (eventId && customerEngagementPlanningData) {
      await duplicateTacticAndTargetsFromEvent(customerEngagementPlanningData);
    }

    return {
      data: customerEngagementPlanningData?.cep || null,
      error,
    };
  };

  return {
    submitCallback,
    cepStateValue,
    eventStateValue,
    onClickNextCustomerInitiative,
    onClickNewCustomerInitiative,
    isOpenCIDialog,
    loadingEvent,
    eventId,
  };
};

export default useCepDuplicate;
