import { AlertColor, Box } from '@mui/material';
import { FC } from 'react';
import useAlertBanner from './AlertBanner.hook';
import { AlertBannerPropsType } from './AlertBanner.types';
import Alert from 'yoda-ui/components/Alert';
import { YodaSpacing } from 'yoda-ui/yodaTheme';

const AlertBanner: FC<AlertBannerPropsType> = ({ type }) => {
  const alert = useAlertBanner(type);

  return (
    <>
      {
        alert?.enabled
          ? <Box sx={ { marginBottom: YodaSpacing.large } }>
            <Alert severity={ alert.severity as AlertColor } >
              { alert.message }
            </Alert>
          </Box>
          : null
      }
    </>
  );
};

export default AlertBanner;
